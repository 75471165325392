
import { defineComponent } from "vue";

export default defineComponent({
  name: "Navigation Bar",
  computed: {
    routes() {
      return this.$router.options.routes;
    },
  },
});
